<template>
    <div class="mb-3 card">
        <div>
            <v-tabs v-model="tab">
                <v-tab key="list">
                    {{ $t('erp.lang_deposit') }}
                </v-tab>
                <v-tab key="add">{{ $t('erp.lang_addPfand') }}</v-tab>
              <v-tab key="settings">{{ $t('settings.lang_settings') }}</v-tab>

                <v-tabs-items v-model="tab">
                    <v-tab-item key="list">
                        <Datatable v-show="showTable" ref="depositDatatable"
                                   :api-endpoint="ENDPOINTS.DATATABLES.ERP.DEPOSIT"
                                   :datatable-headers="datatableHeaders"
                                   :excel-columns="excelColumns"
                                   excel-file-name="Deposit"
                                   @editEntry="entryEdit"
                                   @deleteEntry="entryDelete"
                                   @deleteEntries="entriesDelete"
                                   show-delete-buttons
                                   show-edit-buttons
                                   :permissionDelete="this.$store.getters['permissions/checkPermission']('deposit')"
                                   :permissionEdit="this.$store.getters['permissions/checkPermission']('deposit')"
                        >
                          <template v-slot:item.price="{item}">
                            {{ item.price | currency }}
                          </template>
                        </Datatable>

                        <!-- Table: Click -->
                        <div v-if="showUpdate">
                          <br><br>
                            <v-layout>
                                <v-flex md6 mr-2 ml-2>
                                    <v-text-field outlined
                                                  @focus="showTouchKeyboard"
                                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                  v-model="text"
                                                  :label="$t('erp.lang_deposit')"
                                                  autocomplete="off"
                                                  required
                                    ></v-text-field>
                                </v-flex>
                                <v-flex md6 mr-2 ml-2>
                                    <v-text-field outlined
                                                  @focus="showTouchKeyboard"
                                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                  v-model="price"
                                                  :label="$t('erp.lang_Pfandpreis')"
                                                  autocomplete="off"
                                                  required
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>

                            <v-layout class="pl-3">
                                <v-flex>
                                    <label style="font-size: 120%">
                                        {{ $t("erp.lang_depositType") }}
                                    </label>

                                    <v-checkbox
                                        v-model="type"
                                       :value=1
                                        :label="$t('erp.lang_oneWayDeposit')"
                                        style="margin-top: 0px !important"
                                    ></v-checkbox>
                                    <v-checkbox
                                        v-model="type"
                                        :value=2
                                        :label="$t('erp.lang_multiWayDeposit')"
                                        style="margin-top: 0px !important"
                                    ></v-checkbox>
                                </v-flex>
                            </v-layout>

                            <v-layout>
                                <v-flex class="text-right">
                                    <v-btn text color="error" @click="resetData">{{ $t('generic.lang_prev') }}</v-btn>
                                    <v-btn color="error" @click="deleteData">{{ $t('generic.lang_delete') }}</v-btn>
                                    <v-btn color="success" :disabled="text.length < 1" @click="updateData">{{
                                        $t('generic.lang_edit') }}
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </div>
                    </v-tab-item>
                    <v-tab-item key="add">
                        <v-container fluid><br><br>
                            <v-layout>
                                <v-flex md6 mr-2 ml-2>
                                    <v-text-field outlined
                                                  @focus="showTouchKeyboard"
                                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                  v-model="depositNameAdd"
                                                  :label="$t('erp.lang_deposit')"
                                                  autocomplete="off"
                                                  required
                                    ></v-text-field>
                                </v-flex>
                                <v-flex md6 mr-2 ml-2>
                                    <v-text-field outlined
                                                  @focus="showTouchKeyboard"
                                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                  v-model="depositPriceAdd"
                                                  :label="$t('erp.lang_Pfandpreis')"
                                                  autocomplete="off"
                                                  type="number"
                                                  step="0.01"
                                                  required
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>

                            <v-layout>
                                <v-flex>
                                    <label style="font-size: 120%">
                                        {{ $t("erp.lang_depositType") }}
                                    </label>

                                    <v-checkbox
                                        v-model="depositTypeAdd"
                                       :value=1
                                       :label="$t('erp.lang_oneWayDeposit')"
                                        style="margin-top: 0px !important"
                                    ></v-checkbox>
                                    <v-checkbox
                                        v-model="depositTypeAdd"
                                        :value=2
                                        :label="$t('erp.lang_multiWayDeposit')"
                                        style="margin-top: 0px !important"
                                    ></v-checkbox>
                                </v-flex>
                            </v-layout>

                            <v-layout>
                                <v-flex class="text-right">
                                    <v-btn text color="error" @click="showList">{{ $t('generic.lang_prev') }}</v-btn>
                                    <v-btn color="success" :disabled="depositNameAdd.length < 1" @click="addData">{{
                                        $t('generic.lang_add') }}
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-tab-item>



                  <v-tab-item key="settings">
                    <v-container fluid><br><br>
                      <v-layout>
                        <v-flex md12 mr-2 ml-2>

                          <v-select :items="itemGroupNameSelect"
                                    :label="$t('generic.lang_waregroup')"
                                    item-text="name"
                                    item-value="id"
                                    outlined
                                    v-model="itemGroupName"
                          ></v-select>

                        </v-flex>

                      </v-layout>

                      <v-layout>
                        <v-flex class="text-right">
                          <v-btn text color="error" @click="showList">{{ $t('generic.lang_prev') }}</v-btn>
                          <v-btn color="success" @click="setDepositItemgroup">{{
                              $t('generic.lang_apply') }}
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-tab-item>


                </v-tabs-items>
            </v-tabs>
        </div>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard id="onScreenKeyboard" class="internalWidth" :options="touchKeyboard.options"
                                :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                                :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                                :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
        </div>
    </div>
</template>

<style>
    .dataTables_filter {
        display: none;
    }
</style>

<script>
    import {ENDPOINTS} from '../../../config'
    import {Events} from "../../../plugins/events";
    import Datatable from "../../datatable/Datatable";
    import mixin from "../../../mixins/KeyboardMixIns";

    export default {
        components: {
            Datatable
        },
        mixins: [mixin],
        data() {
            return {
                ENDPOINTS,
                id: null,
                text: "",
                price: 0,
                type: 0,
                depositNameAdd: "",
                depositPriceAdd: 0, 
                depositTypeAdd: 0,
                search: "",
                loading: false,
                showTable: true,
                showCreate: false,
                showUpdate: false,
                tab: 0,
              itemGroupNameSelect: [],
              itemGroupName: 0,
                // --- Datatable ---
                datatableHeaders: [
                    {
                        text: 'ID',
                        align: 'left',
                        value: 'id',
                        width: 80,
                        hide: true
                    },
                    {text: this.$t('erp.lang_deposit'), value: "name",sort: "asc"},
                    {text: this.$t('erp.lang_Pfandpreis'), value: "price"}
                ],
                excelColumns: [

                    {
                        label: "ID",
                        field: 'id',
                    },
                    {
                        label: this.$t('erp.lang_deposit'),
                        field: "name",
                    },
                    {
                        label: this.$t('erp.lang_Pfandpreis'),
                        field: "price",
                    },
                ],
            }
        },

        watch: {
            tab: function () {
                if (this.tab === 1) {
                    this.showTable = true;
                    this.showUpdate = false;
                }
            }
        },

        methods: {
            addData: function () {
                let self = this;
                this.axios.post(ENDPOINTS.ERP.DEPOSIT.CREATE, {
                    pfandName: self.depositNameAdd,
                    pfandPrice: self.depositPriceAdd,
                    type: self.depositTypeAdd
                }).then((res) => {
                    if (res.data.status === 'SUCCESS') {
                        Events.$emit("showSnackbar", {
                            message: this.$t('erp.lang_deposit_added'),
                            color: "success"
                        });
                        self.$refs.depositDatatable.getDataFromApi();
                        self.$refs.depositDatatable.resetSelectedRows();
                        self.depositNameAdd = "";
                        self.depositPriceAdd = 0;
                        self.depositTypeAdd = 0;
                        self.showList();
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch(() => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                });
            },
            createNew: function () {
                this.resetData();
                this.showCreate = true;
                this.tab = 1;
                this.showTable = false;
            },
            updateData: function () {
                let self = this;
                this.axios.post(ENDPOINTS.ERP.DEPOSIT.UPDATE, {
                    pfandID: this.id,
                    pfandName: this.text,
                    pfandPrice: this.price,
                    type: this.type,
                }).then((res) => {
                    if (res.data.status === 'SUCCESS') {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_actionSuccessful'),
                            color: "success"
                        });
                        self.resetData();
                        self.$refs.depositDatatable.getDataFromApi();
                        self.$refs.depositDatatable.resetSelectedRows();
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch(() => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                });
            },
            deleteData: function (idsToDelete = []) {
                let self = this;
                this.$swal({
                    title: this.$t('erp.lang_deleteDeposit'),
                    text: this.$t('erp.lang_deleteDepositText'),
                    icon: "warning",
                    cancelButtonText: this.$t('generic.lang_cancel'),
                    confirmButtonText: this.$t('generic.lang_delete'),
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
                            idsToDelete = [];
                            idsToDelete.push(parseInt(this.id));
                        }
                        this.axios.post(ENDPOINTS.ERP.DEPOSIT.DELETE, {
                            pfandID: idsToDelete
                        }).then((res) => {
                            if (res.data.status === 'SUCCESS') {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('erp.lang_depositDeleted'),
                                    color: "success"
                                });

                                self.resetData();
                                self.$refs.depositDatatable.getDataFromApi();
                                self.$refs.depositDatatable.resetSelectedRows();
                            } else {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('generic.lang_errorOccurred'),
                                    color: "error"
                                });
                            }
                        });
                    },
                    allowOutsideClick: () => !this.$swal.isLoading()
                });
            },

          getData: function () {

            this.axios.post(ENDPOINTS.ERP.DEPOSIT.GETITEMGROUP, {
            }).then((res) => {
              if (res.status === 200) {

                this.itemGroupName = res.data.formfillData.textFields.depositItemgroupId;

                res.data.formfillData.textFields.itemgroups.forEach((row) => {
                  this.itemGroupNameSelect.push({name: row[1], id: parseInt(row[0])});
                });
              }
            }).catch(() => {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            });
          },
          setDepositItemgroup: function () {
            let self = this;
            this.axios.post(ENDPOINTS.ERP.DEPOSIT.UPDATEITEMGROUP, {
              depositItemGroupId: this.itemGroupName,
            }).then((res) => {
              if (res.data.status === 'SUCCESS') {
                Events.$emit("showSnackbar", {
                  message: this.$t('generic.lang_actionSuccessful'),
                  color: "success"
                });
              } else {
                Events.$emit("showSnackbar", {
                  message: this.$t('generic.lang_errorOccurred'),
                  color: "error"
                });
              }
            }).catch(() => {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            });
          },
            entryEdit(entry) {
                let self = this;

                self.axios.post(ENDPOINTS.ERP.DEPOSIT.GET, {
                    pfandID: entry.id
                }).then((res) => {
                    if (res.status === 200) {
                        self.id = entry.id;
                        self.text = res.data.formfillData.textFields.pfandName;
                        self.price = res.data.formfillData.textFields.pfandPrice;
                        self.type = res.data.formfillData.textFields.type;
                        self.showTable = false;
                        self.showUpdate = true;
                    } else {
                        Events.$emit("showSnackbar", {
                            message: self.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch((err) => {
                    Events.$emit("showSnackbar", {
                        message: self.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                });
            },
            entryDelete(entry) {
                this.id = entry.id;

                this.deleteData();
            },
            entriesDelete(entries) {
                this.deleteData(entries);
            },
            resetData: function () {
                this.showCreate = false;
                this.showUpdate = false;
                this.showTable = true;
                this.id = null;
                this.text = "";
            },
            showList: function () {
                this.resetData();
                this.tab = 0;
            },
        },
      mounted() {
        this.getData();
      },
    }
</script>